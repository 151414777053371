<template>
  <component :is="propertyTypeData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="propertyTypeData === undefined"
    >
      <h4 class="alert-heading">
        خطأ في جلب بيانات إدارة أنواع العقار
      </h4>
      <div class="alert-body">
        لم يتم العثور على إدارة أنواع العقار مع معرف إدارة أنواع العقار هذا.راجع
        <b-link
          class="alert-link"
          :to="{ name: 'propertyTypes-list'}"
        >
          قائمة إدارة أنواع العقار
        </b-link>
        إدارة أنواع العقار الآخرين.
      </div>
    </b-alert>

    <b-tabs
      v-if="propertyTypeData"
      pills
    >
      <!-- Tab: Information -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('property_type_edit') }}</span>
        </template>
        <propertyType-edit-tab-information
          :property-type-data="propertyTypeData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import propertyTypeStore from '../propertyTypeStore';
import PropertyTypeEditTabInformation from './PropertyTypeEditTabInformation.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    PropertyTypeEditTabInformation,
  },
  setup() {
    const propertyTypeData = ref(null);

    const PROPERTYTYPE_APP_STORE_MODULE_NAME = 'app-propertyType';

    // Register module
    if (!store.hasModule(PROPERTYTYPE_APP_STORE_MODULE_NAME)) store.registerModule(PROPERTYTYPE_APP_STORE_MODULE_NAME, propertyTypeStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROPERTYTYPE_APP_STORE_MODULE_NAME)) store.unregisterModule(PROPERTYTYPE_APP_STORE_MODULE_NAME);
    });

    store.dispatch('app-propertyType/fetchPropertyType', { id: router.currentRoute.params.id })
      .then((response) => {
        const { data: propertyType } = response.data;
        propertyTypeData.value = propertyType;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          propertyTypeData.value = undefined;
        }
      });

    return {
      propertyTypeData,
    };
  },
};
</script>

<style>

</style>

import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPropertyTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-type', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/property-type/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updatePropertyType(ctx, { propertyTypeData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/property-type/${id}`, propertyTypeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deletePropertyType(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/property-type/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPropertyType(ctx, propertyTypeData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/property-type', propertyTypeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchPropertyClassifications(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('api/property-type/property-classification')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
